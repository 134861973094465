

jQuery(document).ready(function($) {

	
	// HOMME 1
	var header = new ScrollMagic.Controller();
		var item  = $('.slider-img');
		var tween = TweenMax.fromTo(item, 1, { y: '25%'}, { y: '-25%'} );
		var scene = new ScrollMagic.Scene( { triggerHook: "onEnter", duration: "100%" } )

		.setTween( tween )
		.addTo( header );


	// HOMME 2
	var about = new ScrollMagic.Controller();
		var trigger = $('#quisommesnous');
		var item  = $('.img-homme2');
		var tween = TweenMax.fromTo(item, 1, { x: '100%'}, { x: '0%'} );
		var scene = new ScrollMagic.Scene( { triggerElement: trigger, triggerHook: "onEnter", duration: "100%" } )

		.setTween( tween )
		.addTo( about );


	// HOMME 3
/*	var about = new ScrollMagic.Controller();
		var trigger = $('#solution2');
		var item  = $('.frame-text--white2');
		var tween = TweenMax.fromTo(item, 1, { y: '100%'}, { y: '65%'} );
		var scene = new ScrollMagic.Scene( { triggerElement: trigger, triggerHook: "onEnter", duration: "100%" } )

		.setTween( tween )
		.addTo( about );
*/
	
});