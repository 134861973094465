
/*
      ##    #######   ##     ##  ########   ########   ##    ##   
      ##   ##     ##  ##     ##  ##         ##     ##   ##  ##    
      ##   ##     ##  ##     ##  ##         ##     ##    ####     
      ##   ##     ##  ##     ##  ######     ########      ##      
##    ##   ##  ## ##  ##     ##  ##         ##   ##       ##      
##    ##   ##    ##   ##     ##  ##         ##    ##      ##      
 ######     ##### ##   #######   ########   ##     ##     ##      
*/

/**
 * Header style
 */
$(window).scroll(function () {
	if ($(window).scrollTop() === 0) {
		$('html').addClass('ontop');
	}
});


/*
########    #######    ######         ########   ########      ###     ########   ##    ##   
##     ##  ##     ##  ##    ##        ##     ##  ##           ## ##    ##     ##   ##  ##    
##     ##  ##     ##  ##              ##     ##  ##          ##   ##   ##     ##    ####     
##     ##  ##     ##  ##              ########   ######     ##     ##  ##     ##     ##      
##     ##  ##     ##  ##              ##   ##    ##         #########  ##     ##     ##      
##     ##  ##     ##  ##    ##        ##    ##   ##         ##     ##  ##     ##     ##      
########    #######    ######         ##     ##  ########   ##     ##  ########      ##      
*/
$(document).ready(function() {

	FastClick.attach(document.body);

	$('[data-link]').click(function() {
		window.location.href = $(this).attr('data-link');
	}).find('a').click(function(e) {
		e.stopPropagation();
	});

	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();

	/**
	 * Toggle menu
	 */
	$(".nav-toggle").click(function(e){
		e.preventDefault();
		$(".navigation").toggleClass("open");
		$(".lines-button").toggleClass("close");
	});

	/**
	 * Header style
	 */
	$('body').waypoint(function(direction){
		if (direction === 'down') {
			$('html').removeClass('ontop');
		} else {
			$('html').addClass('ontop');
		}
	}, {offset: '-38px' });

	// ----------------------------------
	// MENU
	// ----------------------------------
	/**
	 * Current
	 */
	var links = $('body.home .menu').find('a');
	slide = $('.slide');
	mywindow = $(window);
	htmlbody = $('html,body');

	var scroll = true;

	slide.waypoint(function (direction) {

			dataslide = $(this.element).data('slide');
			

			if(scroll){

				$('body.home .menu a').removeClass('current');

				if (direction === 'down') {
					$('body.home .menu a[data-slide="' + dataslide + '"]').addClass('current');
				}
				else {
					dataslide -= 1;
					$('body.home .menu a[data-slide="' + dataslide + '"]').addClass('current');
				}

			}

	}, { offset:'118px' });

	/**
	 * Scroll to
	 */
	var hash = window.location.hash;
	if(hash) {
		setTimeout(function() {
			$(window).scrollTop(0);
		}, 1);
		setTimeout(function() {
		    $(".menu a[data-anchor='"+hash+"']").click();
		}, 800);
	}

	$("body.home .menu a[data-anchor]").click(function(e) {
		e.preventDefault();
		anchor    = $(this).attr('data-anchor');
		anchorTop = $(anchor).offset().top - 117;
		scroll = false;
		$(".menu a[data-anchor]").removeClass('current');
		$(this).addClass('current');
		$(".navigation").removeClass("open");
		$(".lines-button").removeClass("close");
		$('body,html').animate({scrollTop: anchorTop}, 800, function(){
			scroll = true;
		});
	});



	$("body.home a.arrow-nav[data-anchor]").click(function(e) {
		e.preventDefault();
		anchor    = $(this).attr('data-anchor');
		anchorTop = $(anchor).offset().top - 117;
		scroll = false;
		$(".menu a[data-anchor]").removeClass('current');
		$('body,html').animate({scrollTop: anchorTop}, 800, function(){
			scroll = true;
		});
	});

	$("body.home a.social--bouton[data-anchor]").click(function(e) {
		e.preventDefault();
		anchor    = $(this).attr('data-anchor');
		anchorTop = $(anchor).offset().top - 117;
		scroll = false;
		$(".menu a[data-anchor]").removeClass('current');
		$('body,html').animate({scrollTop: anchorTop}, 800, function(){
			scroll = true;
		});
	});



	/**
	 * FitVids [Video responsive]
	 */
	$(".video-container").fitVids();

	/*
	 ######     #######   ##    ##   ########      ###      ######    ########   
	##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
	##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
	##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
	##         ##     ##  ##  ####      ##      #########  ##            ##      
	##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
	 ######     #######   ##    ##      ##      ##     ##   ######       ##      
	*/
	$("form").validationEngine({ 
		scroll: false,
		showPrompts: true,
		onFieldFailure: function(_input){
			_input.parent('li').addClass('error');
		},
		onFieldSuccess: function(_input){
			_input.parent('li').removeClass('error');
		}
	});

	$("input").focusin(function() {
		$(this).parent('li').addClass('input-active');
	}).blur(function() {
		if( $(this).val() === "" ) $(this).parent('li').removeClass('input-active');		
	});

	$("#fDevis").submit(function(){

		if($("#fDevis").validationEngine('validate')){
			var $form = $(this);
			grecaptcha.ready(function() {
				grecaptcha.execute('6Lf6OawgAAAAAKQiKMsyQ7FzJB0KrfGsXQ6JCw4w', {action: 'submit'}).then(function(token) {
					var data = $form.serialize() + '&act=envoi&recaptcha='+token;
					var div  = $("button[type='submit']").html('envoi en cours...').prop('disabled', true);
					var url  = $form.attr("action");

					$.ajax({
						type    : "POST",
						data    : data,
						url     : url,
						success : function(retour) {
							div.html(retour);
						}
					});
				});
			});
		}

		return false;
	});

});


var $links = $('.frame-toggle');
$links.click(function(){
   $(this).next('.frame-content').slideToggle(300);
});

$('.btn-paie').click( function() {
   $('.btn-paie').addClass('btn-active');
   $('.btn-rh').removeClass('btn-active');
   $('.content-rh').removeClass('visible');
   $('.content-paie').addClass('visible');
});

$('.btn-rh').click( function() {
   $('.btn-rh').addClass('btn-active');
   $('.btn-paie').removeClass('btn-active');
   $('.content-rh').addClass('visible');
   $('.content-paie').removeClass('visible');
});

$('#mentions').click( function() {
   $('.mentions').addClass('visible');
   $('.mentions').removeClass('hidden');
   $.fn.matchHeight._update();
});

$('.close').click( function() {
   $('.mentions').addClass('hidden');
});